import React, { useRef } from "react"
import "flickity/css/flickity.css"
import Left from "./assets/chevron-left.png"
import Right from "./assets/chevron-right.png"
import styled from "@emotion/styled"
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null
import { GatsbyImage } from "gatsby-plugin-image"

const flickityOptions = {
  freeScroll: false,
  pageDots: false,
  wrapAround: true,
  prevNextButtons: true,
  cellAlign: "left",
}

const CompanySlider = ({ data }) => {
  var prevNext = useRef()
  return typeof window !== "undefined" ? (
    <Carousel>
      <div style={{ width: "100%" }}>
        <Flickity
          flickityRef={c => (prevNext = c)}
          options={flickityOptions}
          className={"carousel"}
          reloadOnUpdate
        >
          {data.map((item, index) => {
            return (
              item.image_file && (
                <Card key={index.toString()}>
                  <Image
                    image={
                      item.image_file.localFile.childImageSharp.gatsbyImageData
                    }
                    objectFit="contain"
                    alt=""
                  />
                </Card>
              )
            )
          })}
        </Flickity>
      </div>
    </Carousel>
  ) : null
}

const Card = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  margin: 0 6px;
  padding: 5px;
  background: white;

  :not(.is-selected) {
    @media (max-width: 1100px) {
      opacity: 0.4;
    }
  }
`

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin: 0;

  img {
    object-fit: contain !important;
  }
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0 0 0;

  .carousel {
    margin: 0px 35px;

    @media (max-width: 600px) {
      margin: 0px -20px;
    }
  }

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 30px;
    height: 30px;
    border: 2px solid #111;

    :hover {
      background-color: #111;
      color: #fff;
    }
  }
  .flickity-prev-next-button.next {
    right: -35px;

    @media (max-width: 600px) {
      right: 15px;
    }
  }
  .flickity-prev-next-button.previous {
    left: -35px;

    @media (max-width: 600px) {
      left: 15px;
    }
  }
`

const PrevNext = styled.div`
  margin: 0 10px;
  width: 20px;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  :hover {
    cursor: pointer;
  }
`

const Indicators = styled.img`
  margin: 0;
`

export default CompanySlider
