import React, { Fragment, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { FaAngleDown, FaAngleRight } from "react-icons/fa"
import { useLocation } from "@reach/router"

import {
  ProductTitleWrapper,
  ProductSingleTitle,
  ProductInfoSection,
  Container,
  ProductTopDesktopSection,
  ProductInfoBox,
  ProductImageSection,
  ProductOverlay,
  ProductMobileBelowImageSection,
  ProductRightInfoSection,
  ProductSummaryTop,
  ProductSummaryBottom,
  ProductBottomInfo,
  DesktopProductBottomPriceInfo,
  MobileProductBottomPriceInfo,
  ProductBottomCartButtonBox,
  ProductInfoBottom,
  DesktopPrice,
  ProductAlmaPriceWrapper,
  ProductAlmaPrice,
  ProductPriceWrapper,
  ProductPrice,
  ProductPlusDetail,
  ProductAlmaDetail,
  ProductInfoBottomRight,
  ProductAddToCart,
  Select,
  LayerDisabled,
  ConfigSize,
  UserTypeWrapper,
  ChooseGenderText,
  ErrorBox,
  DropIcon,
  MobileSelect,
  DropIconMobile,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  LyneupSizeErrorBox,
  ProductSizeVariationWrapper
} from "../../../components/Products/styled";

import ProductTopSection from "../../../components/Products/ProductTopSection"
import ProductImageSlider from "../../../components/Products/ProductImageSlider"
import ProductMaterialInfo from "../../../components/Products/ProductMaterialInfo"
import ProductUserType from "../../../components/Products/ProductUserType"
import ProductColorBox from "../../../components/Products/ProductColorBox"
import ProductSizeDetail from "../../../components/Products/ProductSizeDetail"
import {
  VariationBox,
  ProductSizeVariationBoxWrapper,
} from "../../../components/Products/ProductSizeVariationBox"
import ProductQuantity from "../../../components/Products/ProductQuantity"
import ProductMobileInfoBanner from "../../../components/Products/ProductMobileInfoBanner"
import ProductBlueBanner from "../../../components/Products/ProductBlueBanner"
import ProductResultSection from "../../../components/Products/ProductResultSection"
import ProductWhyChooseSection from "../../../components/Products/ProductWhyChooseSection"
import ProductExpertSection from "../../../components/Products/ProductExpertSection"
import CompanySection from "../../../components/Products/CompanySection"
import ProductSatisfiedClientSection from "../../../components/Products/ProductSatisfiedClientSection"
import ProductVideoSection from "../../../components/Products/ProductVideoSection"
import ProductReviewSection from "../../../components/Products/ProductReviewSection"
import ProductFaqSection from "../../../components/Products/ProductFaqSection"
import CustomerService from "../../../components/Layout/CustomerService"
import Footer from "../../../components/Layout/Footer"
import Testimonials from "../../../components/Layout/Testimonials"
import {
  CapitalizeFirstLetter,
  colors,
  genders,
  ImageSliders,
  images,
  colorsReverse,
  genderReverse,
} from "../../../utils/lyneUphelpers"
import { withStoreContext } from "../../../context/StoreContext"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import PriceDetails from "../../../components/Products/PriceDetails"
import LyneupSizeGuidePopup from "../../../components/Products/LyneupSizeGuidePopup"
import MobileAddToCartPopup from "../../../components/Products/MobileAddToCartPopup"
import BottomStickyAddToCart from "../../../components/Products/BottomStickyAddToCart"
import SeoComp from "../../../components/SeoComp"
import {
  addLyneHomeUrl,
  addToCartScript,
  productGtmEvent,
  variantUpdate,
} from "../../../utils/additionalScriptHelpers"
import MedicaleInfoSection from "../../../components/Layout/MedicaleInfoSection"
import Loader from "react-loader-spinner"
import LyneupSizeGuideModal from "../../../components/Products/LyneupSizeGuideModal"
import KlarnaPlacementSection from "../../../components/KlarnaPlacementSection"
import {
  SizeSelectorLyneUpFemale,
  SizeSelectorLyneUpMale,
} from "../../../components/SizeSelector"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import { monthlyPrice, navigateToCart } from "../../../utils/navigateToCart"
import TrustPilotReviewSection from "../../../components/Products/TrustPilotReviewSection"

const LyneUp = ({
  intl,
  data,
  data: { shopifyProduct: product },
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  let defaultOptionValues = {}
  let titleBorderColor = "lyneup"
  product.options.forEach(selector => {
    defaultOptionValues[selector.name] = selector.values[0]
  })
  const [selectedVariant, setselectedVariant] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({
    Gender: "",
    Color: "",
    Size: "",
  })
  const [openModal, setopenModal] = useState(false)
  const [openSizeModal, setopenSizeModal] = useState(false)
  const [openAlmaModal, setopenAlmaModal] = useState(false)
  const [openMobileAddToCartModal, setopenMobileAddToCartModal] = useState(
    false
  )
  const [activeFlag, setactiveFlag] = useState(false)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [menSizeParameters, setmenSizeParameters] = useState({
    Age: "",
    Height: "",
    Weight: "",
  })
  const [sizeTypeMen, setsizeTypeMen] = useState(intl.locale)
  const [errorSize, seterrorSize] = useState("")
  const [loading, setLoading] = useState(false)
  const [IsMobile, setIsMobile] = useState(false)
  const [openSizeGuideModal, setopenSizeGuideModal] = useState(false)
  const [recommendation, setRecommendation] = useState({})

  const handleQuantityChange = event => {
    setselectedVariantQuantity(event.target.value)
  }

  const handleParamStrings = selectedOptions => {
    let paramString = ""
    let currUrl = isBrowser && window.location.pathname
    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Gender") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Gender") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else if (item === "Color") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        } else {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })
    isBrowser && history.replaceState(null, null, paramString)
    // navigate(`${currUrl}${paramString}`)
  }

  const removeErros = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if geneder exists
      if (option["Gender"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "gender_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if color exists
      if (option["Color"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "color_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    setselectOptions(selectOptions => {
      let selectedOptions = { ...selectOptions }

      if (target.name === "Color") {
        selectedOptions[target.name] = colors[intl.locale][target.value]
      } else if (target.name === "Gender") {
        selectedOptions[target.name] = genders[intl.locale][target.value]
        seterrorSize("")
        selectedOptions["Size"] = ""
        if (!selectOptions["Color"]) {
          selectedOptions["Color"] = "BLACK"
        }
      } else if (target.name === "Size") {
        if (target.value) {
          selectedOptions[target.name] = target.value
        } else {
          selectedOptions[target.name] = ""
        }
      }

      removeErros(selectedOptions)
      handleParamStrings(selectedOptions)

      if (Object.keys(selectedOptions).length === 3) {
        const selectedVariant = client.product.helpers.variantForOptions(
          product,
          selectedOptions
        )
        if (selectedVariant) {
          setselectedVariant(selectedVariant)
          setErrors({ exist: false, message: [] })
        } else setselectedVariant(null)
      }
      return selectedOptions
    })
    // setselectedVariantImage(
    //   images[selectedOptions["Gender"]][selectedOptions["Color"]]
    // )
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Gender"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "gender_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "gender_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Color"]) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "color_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "color_not_seleceted_error" }),
        ]
    }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element === intl.formatMessage({ id: "size_not_seleceted_error" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error" }),
        ]
    }

    removeErros(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = () => {
    let flatInput = recommendation?.inputs
      ? Object.keys(recommendation.inputs).map(key => ({
          key: key,
          value: `${recommendation.inputs[key]}`,
        }))
      : []

    let flatRecommendation = recommendation?.recommendation
      ? Object.keys(recommendation.recommendation).map(key => ({
          key: key,
          value: `${recommendation.recommendation[key]}`,
        }))
      : []
    // prepare attributes
    let attributes = [...flatInput, ...flatRecommendation]

    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        attributes,
        checkout => {
          addToCartScript(
            product.id,
            product.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          navigateToCart(intl.locale)
        }
      )
    } else {
      handleErrors()
    }
  }

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const genderParam = urlParams.get("Gender")
    const colorParam = urlParams.get("Color")
    const sizeParam = urlParams.get("Size")
    let options = { ...selectOptions }
    // if (Object.keys(options).length === 0) {
    if (genderParam) {
      options.Gender = genderParam
      // genders[intl.locale][genderParam]
      options.Color = "BLACK"
    }

    if (colorParam) {
      options.Color = colorParam
      // colors[intl.locale][colorParam]
    }

    if (sizeParam) {
      options.Size = sizeParam
    }

    if (Object.keys(options).length === 3) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      setselectedVariant(selectedVariant)
    }

    setselectOptions(options)
  }

  const handleScrolling = () => {
    $("#sticy-button").click(function() {
      var w = window.outerWidth
      if (w <= 600) {
        $("html, body").animate(
          {
            scrollTop: $("#mobile_below_image").offset().top - 150,
          },
          1500
        )
      } else {
        $("html, body").animate(
          {
            scrollTop: $("body").offset().top,
          },
          1500
        )
      }
      handleErrors()
    })
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  //   const handleErrosInitial = () => {
  //     let errors = { ...Errors }
  //     errors.exist = false
  //     errors.message = []
  //     setErrors(errors)
  //   }

  const handleSizeRecommendationUpdate = data => {
    if (data.recommendation) {
      let selectedOptions = { ...selectOptions }

      if (data.recommendation.size?.length) {
        selectedOptions["Size"] = data.recommendation.size
        setselectOptions(selectedOptions)

        handleChangeSelectOptions({
          target: { name: "Size", value: data.recommendation.size },
        })

        setRecommendation(data)

        if (selectOptions["Gender"] === "Male") {
          setmenSizeParameters(data)
        }

        seterrorSize("")
      } else {
        seterrorSize(
          intl
            .formatMessage({ id: "size_selector.lyneup_size_error_message" })
            .replace(
              "/shop/",
              addLyneHomeUrl(intl.locale, selectOptions["Gender"])
            )
        )
      }
    }
  }

  const returnSizeSelector = mobile => {
    return (
      <>
        <ProductSizeVariationBoxWrapper
            show={selectOptions["Gender"] === "Male"}
          mobile={mobile}
          style={{ marginBottom: 0 }}
        >
          <SizeSelectorLyneUpMale
            setLoading={setLoading}
            mobile={mobile}
            onChange={handleSizeRecommendationUpdate}
          />
        </ProductSizeVariationBoxWrapper>

        <ProductSizeVariationBoxWrapper
            show={selectOptions["Gender"] === "Female"}
          mobile={mobile}
          style={{ marginBottom: 0 }}
        >
          <SizeSelectorLyneUpFemale
            mobile={mobile}
            onChange={handleSizeRecommendationUpdate}
          />
        </ProductSizeVariationBoxWrapper>

        {errorSize && (
          <LyneupSizeErrorBox dangerouslySetInnerHTML={{ __html: errorSize }} />
        )}
      </>
    )
  }

  const windowResize = () => {
    var w = window.outerWidth
    if (w <= 600) {
      setIsMobile(true)
    }
    $(window).resize(() => {
      var w = window.outerWidth
      if (w <= 600) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  const mounted = useRef()
  var searchLocation = useLocation()
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      BottomBarActive()
      // handleErrosInitial()
      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    } else {
      variantUpdate(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    }
  }, [selectedVariant, selectOptions])

  useEffect(() => {
    handleScrolling()
    windowResize()
  }, [selectOptions])

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  return (
    data &&
    data.allDirectusLyneupTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="lyneup"
          hreflangPathname={{
            fr: `shop/lyneup`,
            en: `shop/lyneup`,
            de: `shop/lyneup`,
            es: `shop/lyneup`,
          }}
        />
        <BottomStickyAddToCart
          title={data.allDirectusLyneupTranslation.nodes[0].product_title}
          image={
            data.allDirectusLyneupTranslation.nodes[0].product_title_logo_image
          }
          gender={genderReverse[intl.locale][selectOptions["Gender"]]}
          cart_text={
            !selectOptions["Gender"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_gender",
                })
              : !selectOptions["Size"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_size",
                })
              : data.allDirectusLyneupTranslation.nodes[0]
                  .product_add_to_cart_button
          }
          regularPrice={Number(
            selectedVariant
              ? selectedVariant.price
              : product.priceRangeV2.maxVariantPrice.amount
          ).toFixed(0)}
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          active={activeFlag}
          setopenMobileAddToCartModal={
            IsMobile && selectOptions["Gender"] && !selectOptions["Size"]
              ? setopenMobileAddToCartModal
              : null
          }
          id="sticy-button"
          // openSizeCalcModal={openSizeModal}
        />
        <PriceDetails
          details={data.allDirectusLyneupTranslation.nodes[0].price_details}
          open={openModal}
          closeHandle={setopenModal}
        />
        {/* <PriceDetails
          details={data.allDirectusLyneupTranslation.nodes[0].size_popup_text}
          open={openSizeModal}
          closeHandle={setopenSizeModal}
        /> */}
        {!IsMobile ? (
          <LyneupSizeGuidePopup
            data={data.allDirectusLyneupTranslation.nodes[0]}
            details={data.allDirectusLyneupTranslation.nodes[0].size_popup_text}
            gender={selectOptions["Gender"]}
            open={openSizeModal}
            closeHandle={setopenSizeModal}
          />
        ) : (
          <LyneupSizeGuideModal
            data={data.allDirectusLyneupNewPageTranslation.nodes[0]}
            details={data.allDirectusLyneupTranslation.nodes[0].size_popup_text}
            size_data={data.allDirectusLyneupTranslation.nodes[0].size}
            color_data={data.allDirectusLyneupTranslation.nodes[0].color}
            gender={selectOptions["Gender"]}
            open={openSizeModal}
            closeHandle={setopenSizeModal}
            addToCart={handleAddTocart}
            handleSelectOptions={handleChangeSelectOptions}
            addToCartText={
              data.allDirectusLyneupTranslation.nodes[0]
                .product_add_to_cart_button
            }
            size={selectOptions["Size"]}
            color={colorsReverse[intl.locale][selectOptions["Color"]]}
            formatText={CapitalizeFirstLetter}
            quantity={selectedVariantQuantity}
            quantitySelector={setselectedVariantQuantity}
            handleQuantity={handleQuantityChange}
            variant={selectedVariant}
            material_info={
              data.allDirectusLyneupTranslation.nodes[0].product_material_info
            }
            setopenSizeModal={setopenSizeGuideModal}
            selectOptions={selectOptions}
            Errors={Errors}
            returnSizeSelector={returnSizeSelector}
            one_size_system_text={
              data.allDirectusLyneupTranslation.nodes[0].one_size_system_text
            }
            morphology_text={
              data.allDirectusLyneupTranslation.nodes[0].morphology_text
            }
            i_calculate_text={
              data.allDirectusLyneupTranslation.nodes[0].i_calculate_text
            }
            most_suited_text={
              data.allDirectusLyneupTranslation.nodes[0].most_suited_text
            }
            edit_my_information_text={
              data.allDirectusLyneupTranslation.nodes[0]
                .edit_my_information_text
            }
            return_text={data.allDirectusLyneupTranslation.nodes[0].return_text}
            menSizeOptions={menSizeParameters}
            menerrorSize={errorSize}
            setmenerrorSize={seterrorSize}
            loading={storeContext.adding ? "adding" : ""}
          />
        )}

        <PriceDetails
          details={data.allDirectusLyneupTranslation.nodes[0].alma_popup_text}
          open={openAlmaModal}
          closeHandle={setopenAlmaModal}
        />
        <MobileAddToCartPopup
          size_data={data.allDirectusLyneupTranslation.nodes[0].size}
          color_data={data.allDirectusLyneupTranslation.nodes[0].color}
          open={openMobileAddToCartModal}
          closeHandle={setopenMobileAddToCartModal}
          addToCart={handleAddTocart}
          handleSelectOptions={handleChangeSelectOptions}
          addToCartText={
            data.allDirectusLyneupTranslation.nodes[0]
              .product_add_to_cart_button
          }
          size={selectOptions["Size"]}
          color={colorsReverse[intl.locale][selectOptions["Color"]]}
          formatText={CapitalizeFirstLetter}
          quantity={selectedVariantQuantity}
          quantitySelector={setselectedVariantQuantity}
          handleQuantity={handleQuantityChange}
          variant={selectedVariant}
          material_info={
            data.allDirectusLyneupTranslation.nodes[0].product_material_info
          }
          setopenSizeModal={setopenSizeModal}
          selectOptions={selectOptions}
          Errors={Errors}
          returnSizeSelector={returnSizeSelector}
          one_size_system_text={
            data.allDirectusLyneupTranslation.nodes[0].one_size_system_text
          }
          morphology_text={
            data.allDirectusLyneupTranslation.nodes[0].morphology_text
          }
          i_calculate_text={
            data.allDirectusLyneupTranslation.nodes[0].i_calculate_text
          }
          most_suited_text={
            data.allDirectusLyneupTranslation.nodes[0].most_suited_text
          }
          edit_my_information_text={
            data.allDirectusLyneupTranslation.nodes[0].edit_my_information_text
          }
          return_text={data.allDirectusLyneupTranslation.nodes[0].return_text}
          menSizeOptions={menSizeParameters}
          menerrorSize={errorSize}
          setmenerrorSize={seterrorSize}
          loading={loading}
          addToCartLoading={storeContext.adding ? "adding" : ""}
        >
          <ProductSizeDetail
            data={
              data.allDirectusLyneupTranslation.nodes[0].product_material_info
            }
            openSizeModal={setopenSizeModal}
            disabled={!(selectOptions && selectOptions["Gender"])}
            mobile
          />
        </MobileAddToCartPopup>
        {data.allDirectusLyneupTranslation.nodes[0].product_title && (
          <ProductTitleWrapper>
            <ProductSingleTitle
              dangerouslySetInnerHTML={{
                __html:
                  data.allDirectusLyneupTranslation.nodes[0].product_title,
              }}
            />
          </ProductTitleWrapper>
        )}
        <ProductTopDesktopSection>
          <Container>
            <ProductTopSection
              image={
                data.allDirectusLyneupTranslation.nodes[0]
                  .product_title_logo_image
              }
              review_data={data.allDirectusReviewTranslation.nodes}
              bestseller={
                data.allDirectusLyneupTranslation.nodes[0]
                  .product_bestseller_text
              }
              material_info={
                data.allDirectusLyneupTranslation.nodes[0].product_material_info
              }
              price={Number(
                selectedVariant
                  ? selectedVariant.price
                  : product.priceRangeV2.maxVariantPrice.amount
              ).toFixed(0)}
            >
              <UserTypeWrapper tablet={true}>
                {data.allDirectusLyneupTranslation.nodes[0]
                  .choose_gender_text && (
                  <ChooseGenderText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLyneupTranslation.nodes[0]
                          .choose_gender_text,
                    }}
                  />
                )}
                <ProductUserType
                  data={data.allDirectusLyneupTranslation.nodes[0].gender.item}
                  handleChange={handleChangeSelectOptions}
                  value={selectOptions["Gender"]}
                  product={"Lyne UP"}
                />
              </UserTypeWrapper>
            </ProductTopSection>
          </Container>
        </ProductTopDesktopSection>
        <ProductInfoSection id="info-section">
          <Container>
            <ProductInfoBox>
              <ProductImageSection>
                {!(selectOptions["Gender"] && selectOptions["Color"]) && (
                  <ProductImageSlider
                    data={ImageSliders[intl.locale]["Default"]}
                    medicaleLogo={
                      data.allDirectusCommonTranslation.nodes[0]
                        .medicale_logo &&
                      data.allDirectusCommonTranslation.nodes[0].medicale_logo
                        .data.url
                        ? data.allDirectusCommonTranslation.nodes[0]
                            .medicale_logo.data.url
                        : "https://static.percko.com/uploads/680a855d-5e53-4258-9bdd-20d332b38c16.png"
                    }
                  />
                )}

                {selectOptions["Gender"] && selectOptions["Color"] && (
                  <ProductImageSlider
                    data={
                      selectOptions["Gender"] && selectOptions["Color"]
                        ? ImageSliders[intl.locale][selectOptions["Gender"]][
                            selectOptions[["Color"]]
                          ]
                        : data.allDirectusLyneupTranslation.nodes[0]
                            .product_images_section.men.noir
                    }
                    medicaleLogo={
                      data.allDirectusCommonTranslation.nodes[0]
                        .medicale_logo &&
                      data.allDirectusCommonTranslation.nodes[0].medicale_logo
                        .data.url
                        ? data.allDirectusCommonTranslation.nodes[0]
                            .medicale_logo.data.url
                        : "https://static.percko.com/uploads/680a855d-5e53-4258-9bdd-20d332b38c16.png"
                    }
                  />
                )}

                <ProductMobileInfoBanner
                  data={
                    data.allDirectusLyneupTranslation.nodes[0]
                      .product_blue_banner_text
                  }
                />
              </ProductImageSection>

              <ProductMobileBelowImageSection id="mobile_below_image">
                <ProductTopSection
                  image={
                    data.allDirectusLyneupTranslation.nodes[0]
                      .product_title_logo_image
                  }
                  review_data={data.allDirectusReviewTranslation.nodes}
                  bestseller={
                    data.allDirectusLyneupTranslation.nodes[0]
                      .product_bestseller_text
                  }
                  material_info={
                    data.allDirectusLyneupTranslation.nodes[0]
                      .product_material_info
                  }
                  price={Number(
                    selectedVariant
                      ? selectedVariant.price
                      : product.priceRangeV2.maxVariantPrice.amount
                  ).toFixed(0)}
                  openModal={setopenModal}
                  alma={
                    process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                    intl.locale === "fr" &&
                    true
                  }
                  klarna={intl.locale === "en" && true}
                ></ProductTopSection>
              </ProductMobileBelowImageSection>

              <ProductRightInfoSection>
                <ProductSummaryTop>
                  <ProductMaterialInfo
                    data={
                      data.allDirectusLyneupTranslation.nodes[0]
                        .product_material_info
                    }
                  />
                  {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                    intl.locale === "fr" && (
                      <MobileProductBottomPriceInfo>
                        <ProductPriceWrapper align="flex-start">
                          <ProductPrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                          </ProductPrice>
                          <ProductPlusDetail
                            onClick={() => setopenModal(!openAlmaModal)}
                          >
                            {intl.formatMessage({
                              id: "product_plus_details_text",
                            })}
                          </ProductPlusDetail>
                        </ProductPriceWrapper>
                        <ProductAlmaPriceWrapper>
                          <ProductAlmaPrice>
                            ou 3 x{" "}
                            <span>
                              {monthlyPrice(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              )}
                            </span>
                          </ProductAlmaPrice>
                          <ProductAlmaDetail
                            onClick={() => setopenAlmaModal(!openAlmaModal)}
                          >
                            {intl.formatMessage({
                              id: "alma_details_text",
                            })}
                          </ProductAlmaDetail>
                        </ProductAlmaPriceWrapper>
                      </MobileProductBottomPriceInfo>
                    )}
                  {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                    <MobileProductBottomPriceInfo>
                      <ProductPriceWrapper align="flex-start">
                        <ProductPrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ProductPrice>
                      </ProductPriceWrapper>
                      <ProductAlmaPriceWrapper style={{ maxWidth: "215px" }}>
                        <KlarnaPlacementSection
                          dataKey="credit-promotion-auto-size"
                          price={Number(
                            selectedVariant
                              ? selectedVariant.price
                              : product.priceRangeV2.maxVariantPrice.amount
                          ).toFixed(0)}
                        />
                      </ProductAlmaPriceWrapper>
                    </MobileProductBottomPriceInfo>
                  )}
                  <UserTypeWrapper>
                    {data.allDirectusLyneupTranslation.nodes[0]
                      .choose_gender_text && (
                      <ChooseGenderText
                        dangerouslySetInnerHTML={{
                          __html:
                            data.allDirectusLyneupTranslation.nodes[0]
                              .choose_gender_text,
                        }}
                      />
                    )}
                    <ProductUserType
                      data={
                        data.allDirectusLyneupTranslation.nodes[0].gender.item
                      }
                      handleChange={handleChangeSelectOptions}
                      value={selectOptions["Gender"]}
                      error={selectOptions["Gender"] ? null : Errors.exist}
                      product={"Lyne UP"}
                    />
                  </UserTypeWrapper>

                  <ProductColorBox
                    data={data.allDirectusLyneupTranslation.nodes[0].color.item}
                    handleChange={handleChangeSelectOptions}
                    disabled={!(selectOptions && selectOptions["Gender"])}
                    value={selectOptions["Color"]}
                    colors={colors}
                    colorsReverse={colorsReverse}
                    formatText={CapitalizeFirstLetter}
                    error={!selectOptions["Color"] && Errors.exist}
                  />
                  <ProductSizeDetail
                    data={
                      data.allDirectusLyneupTranslation.nodes[0]
                        .product_material_info
                    }
                    openSizeModal={setopenSizeModal}
                    disabled={!(selectOptions && selectOptions["Gender"])}
                  />
                </ProductSummaryTop>
                <ProductSummaryBottom>
                  <ProductSizeVariationWrapper>
                    {!openMobileAddToCartModal && returnSizeSelector(false)}
                  </ProductSizeVariationWrapper>
                  <div
                    className="size_drop_down"
                    style={{
                      display: errorSize && !IsMobile ? "none" : "block",
                    }}
                  >
                    {!(selectOptions && selectOptions["Gender"]) && (
                      <LayerDisabled />
                    )}
                    <div className="size_drop_down_child">
                      {loading && (
                        <div
                          style={{
                            backgroundColor: "transparent",
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            paddingTop: 3.5,
                          }}
                        >
                          <Loader
                            type="Oval"
                            color="#808080"
                            height={30}
                            width={30}
                            secondaryColor={"#696969"}
                          />
                        </div>
                      )}
                      <Select
                        id="pa_taille_classique"
                        name="Size"
                        onChange={handleChangeSelectOptions}
                        value={selectOptions["Size"]}
                        error={!selectOptions["Size"] && Errors.exist}
                      >
                        <option value={""}>
                          {intl.formatMessage({ id: "my_percko_size_text" })}
                        </option>
                        {data.allDirectusLyneupTranslation.nodes[0].size.item.map(
                          value => (
                            <option value={value} key={`Size-${value}`}>
                              {intl.formatMessage({
                                id: "my_percko_size_text",
                              })}
                              {" : "}
                              {value}
                            </option>
                          )
                        )}
                      </Select>
                      <MobileSelect
                        onClick={() => setopenMobileAddToCartModal(true)}
                      >
                        {
                          data.allDirectusLyneupTranslation.nodes[0]
                            .my_custom_size_text
                        }
                      </MobileSelect>
                      {/* <DropIconMobile>
                        <FaAngleRight size={16} />
                      </DropIconMobile>
                      <DropIcon>
                        <FaAngleDown size={16} />
                      </DropIcon> */}
                    </div>
                  </div>
                  {Errors && Errors.exist && (
                    <ErrorBox>
                      {Errors.message.map((item, index) => (
                        <span key={index.toString()}>{item}</span>
                      ))}
                    </ErrorBox>
                  )}
                  {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                    intl.locale === "fr" && (
                      <ProductBottomInfo>
                        <DesktopProductBottomPriceInfo>
                          <ProductPriceWrapper>
                            <ProductPrice>
                              {`${
                                process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }${Number(
                                selectedVariant
                                  ? selectedVariant.price
                                  : product.priceRangeV2.maxVariantPrice.amount
                              ).toFixed(0)}${
                                process.env.GATSBY_CURRENCY_TYPE === "eur"
                                  ? process.env.GATSBY_CURRENCY
                                  : ""
                              }`}
                            </ProductPrice>
                            <ProductPlusDetail
                              onClick={() => setopenModal(!openModal)}
                            >
                              {intl.formatMessage({
                                id: "product_plus_details_text",
                              })}
                            </ProductPlusDetail>
                          </ProductPriceWrapper>
                          <ProductAlmaPriceWrapper>
                            <ProductAlmaPrice>
                              ou 3 x{" "}
                              <span>
                                {monthlyPrice(
                                  selectedVariant
                                    ? selectedVariant.price
                                    : product.priceRangeV2.maxVariantPrice
                                        .amount
                                )}
                              </span>
                            </ProductAlmaPrice>
                            <ProductAlmaDetail
                              onClick={() => setopenAlmaModal(!openModal)}
                            >
                              {intl.formatMessage({
                                id: "alma_details_text",
                              })}
                            </ProductAlmaDetail>
                          </ProductAlmaPriceWrapper>
                        </DesktopProductBottomPriceInfo>
                        <ProductBottomCartButtonBox>
                          <ProductQuantity
                            disabled={!selectOptions["Size"]}
                            handleQuantityChange={handleQuantityChange}
                            selectedVariantQuantity={selectedVariantQuantity}
                            alma={true}
                          />
                          <div style={{ width: "100%" }}>
                            <ProductAddToCart
                              id="add_to_cart"
                              loading={storeContext.adding ? "adding" : ""}
                              onClick={
                                selectOptions["Gender"]
                                  ? handleAddTocart
                                  : () => {}
                              }
                              disabled={selectOptions["Gender"] ? false : true}
                            >
                              {
                                data.allDirectusLyneupTranslation.nodes[0]
                                  .product_add_to_cart_button
                              }
                            </ProductAddToCart>
                            {selectOptions["Size"] && (
                              <ProductEstimateShippingBox>
                                <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                                <ProductEstimateShippingText>
                                  {cartEstimateDeliveryText(
                                    "LyneUp",
                                    data.allDirectusCommonTranslation.nodes[0]
                                      .months_text,
                                    intl.locale,
                                    selectOptions["Size"],
                                    selectOptions["Gender"],
                                    selectOptions["Color"]
                                  )}
                                </ProductEstimateShippingText>
                              </ProductEstimateShippingBox>
                            )}
                          </div>
                        </ProductBottomCartButtonBox>
                      </ProductBottomInfo>
                    )}
                  {process.env.GATSBY_CURRENCY_TYPE === "gbp" && (
                    <ProductBottomInfo>
                      <DesktopProductBottomPriceInfo>
                        <ProductPriceWrapper>
                          <ProductPrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                          </ProductPrice>
                        </ProductPriceWrapper>
                        <ProductAlmaPriceWrapper style={{ maxWidth: "215px" }}>
                          <KlarnaPlacementSection
                            dataKey="credit-promotion-auto-size"
                            price={Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}
                          />
                        </ProductAlmaPriceWrapper>
                      </DesktopProductBottomPriceInfo>
                      <ProductBottomCartButtonBox>
                        <ProductQuantity
                          disabled={!selectOptions["Size"]}
                          handleQuantityChange={handleQuantityChange}
                          selectedVariantQuantity={selectedVariantQuantity}
                          alma={true}
                        />
                        <div style={{ width: "100%" }}>
                          <ProductAddToCart
                            id="add_to_cart"
                            loading={storeContext.adding ? "adding" : ""}
                            onClick={
                              selectOptions["Gender"]
                                ? handleAddTocart
                                : () => {}
                            }
                            disabled={selectOptions["Gender"] ? false : true}
                          >
                            {
                              data.allDirectusLyneupTranslation.nodes[0]
                                .product_add_to_cart_button
                            }
                          </ProductAddToCart>
                          {selectOptions["Size"] && (
                            <ProductEstimateShippingBox>
                              <ProductEstimateShippingIcon
                                className="lazyload"
                                data-src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg"
                              />
                              <ProductEstimateShippingText>
                                {cartEstimateDeliveryText(
                                  "LyneUp",
                                  data.allDirectusCommonTranslation.nodes[0]
                                    .months_text,
                                  intl.locale,
                                  selectOptions["Size"],
                                  selectOptions["Gender"],
                                  selectOptions["Color"]
                                )}
                              </ProductEstimateShippingText>
                            </ProductEstimateShippingBox>
                          )}
                        </div>
                      </ProductBottomCartButtonBox>
                    </ProductBottomInfo>
                  )}
                  {(process.env.GATSBY_CURRENCY_TYPE === "cad" ||
                    process.env.GATSBY_CURRENCY_TYPE === "usd" ||
                    (process.env.GATSBY_CURRENCY_TYPE === "eur" &&
                      intl.locale != "fr")) && (
                    <ProductInfoBottom>
                      <DesktopPrice>
                        <ProductPriceWrapper>
                          <ProductPrice>
                            {`${
                              process.env.GATSBY_CURRENCY_TYPE !== "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }${Number(
                              selectedVariant
                                ? selectedVariant.price
                                : product.priceRangeV2.maxVariantPrice.amount
                            ).toFixed(0)}${
                              process.env.GATSBY_CURRENCY_TYPE === "eur"
                                ? process.env.GATSBY_CURRENCY
                                : ""
                            }`}
                          </ProductPrice>
                          <ProductPlusDetail
                            onClick={() => setopenModal(!openModal)}
                          >
                            {intl.formatMessage({
                              id: "product_plus_details_text",
                            })}
                          </ProductPlusDetail>
                        </ProductPriceWrapper>
                      </DesktopPrice>
                      <ProductInfoBottomRight>
                        <ProductQuantity
                          disabled={!selectOptions["Size"]}
                          handleQuantityChange={handleQuantityChange}
                          selectedVariantQuantity={selectedVariantQuantity}
                        />
                        <div style={{ width: "100%" }}>
                          <ProductAddToCart
                            id="add_to_cart"
                            loading={storeContext.adding ? "adding" : ""}
                            onClick={
                              selectOptions["Gender"]
                                ? handleAddTocart
                                : () => {}
                            }
                            disabled={selectOptions["Gender"] ? false : true}
                          >
                            {
                              data.allDirectusLyneupTranslation.nodes[0]
                                .product_add_to_cart_button
                            }
                          </ProductAddToCart>
                          {selectOptions["Size"] && (
                            <ProductEstimateShippingBox>
                              <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                              <ProductEstimateShippingText>
                                {cartEstimateDeliveryText(
                                  "LyneUp",
                                  data.allDirectusCommonTranslation.nodes[0]
                                    .months_text,
                                  intl.locale,
                                  selectOptions["Size"],
                                  selectOptions["Gender"],
                                  selectOptions["Color"]
                                )}
                              </ProductEstimateShippingText>
                            </ProductEstimateShippingBox>
                          )}
                        </div>
                      </ProductInfoBottomRight>
                    </ProductInfoBottom>
                  )}
                </ProductSummaryBottom>
              </ProductRightInfoSection>
            </ProductInfoBox>
          </Container>
        </ProductInfoSection>
        <ProductBlueBanner
          data={
            data.allDirectusLyneupTranslation.nodes[0].product_blue_banner_text
          }
          background="#5DB9BC"
        />
        <ProductResultSection
          borderColor={titleBorderColor}
          data={
            data.allDirectusLyneupTranslation.nodes[0].product_result_section
          }
        />
        {data.allDirectusCommonTranslation.nodes[0].medicale_text && (
          <MedicaleInfoSection
            data={data.allDirectusCommonTranslation.nodes[0].medicale_text}
          />
        )}
        <ProductWhyChooseSection
          borderColor={titleBorderColor}
          data={
            data.allDirectusLyneupTranslation.nodes[0]
              .product_why_choose_section
          }
        />
        <ProductExpertSection
          borderColor={titleBorderColor}
          data={
            data.allDirectusLyneupTranslation.nodes[0].product_expert_section
          }
        />
        {data.allDirectusAccquiredCompany.nodes.length > 0 && (
          <CompanySection
            borderColor={titleBorderColor}
            data={data.allDirectusAccquiredCompany.nodes}
            title={
              data.allDirectusLyneupTranslation.nodes[0]
                .product_companies_logo_slider.title
            }
            subtitle={
              data.allDirectusLyneupTranslation.nodes[0]
                .product_companies_logo_slider.subtitle
            }
          />
        )}
        <ProductSatisfiedClientSection
          borderColor={titleBorderColor}
          data={
            data.allDirectusLyneupTranslation.nodes[0]
              .product_satisfied_client_section
          }
        />
        <ProductVideoSection
          data={
            data.allDirectusLyneupTranslation.nodes[0].product_video_testimonial
          }
        />
        {/* <ProductReviewSection
          data={data.allDirectusReviewTranslation.nodes}
          id={"review_section"}
          page="Lyne UP"
        /> */}

        {data.allDirectusReviewTranslation.nodes && (
          <TrustPilotReviewSection
            data={data.allDirectusReviewTranslation.nodes}
            id={"review_section"}
          />
        )}
        {process.env &&
        data.allDirectusLyneupTranslation.nodes[0].gbp_product_faq_section &&
        process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLyneupTranslation.nodes[0].gbp_product_faq_section
            }
          />
        ) : (
          <ProductFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLyneupTranslation.nodes[0].product_faq_section
            }
          />
        )}
        {data.allDirectusHomeTranslation.nodes[0].customer_servcie_text && (
          <CustomerService
            text={
              data.allDirectusHomeTranslation.nodes[0].customer_servcie_text
            }
          />
        )}
        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}
        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusHomeTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        customer_servcie_text
      }
    }
    allDirectusLyneupTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_title
        product_title_logo_image {
          data {
            url
          }
        }
        product_bestseller_text
        product_images_section {
          default_image
          men {
            noir
            nude
          }
          women {
            noir
            nude
          }
        }
        product_material_info
        product_add_to_cart_button
        product_blue_banner_text
        product_result_section {
          section_title
          list {
            title
            mobile_title
            text
          }
        }
        product_why_choose_section {
          section_title
          mobile_section_title
          slider {
            image
            title
            text
          }
          text_list {
            image
            text
            mobile_text
          }
        }
        product_expert_section {
          section_title
          mobile_section_title
          list {
            image
            name
            designation
            text
            video_text
            video_url
          }
        }
        product_satisfied_client_section {
          title
          mobile_title
          subtitle
          left_text {
            title
            text
          }
          right_text {
            title
            text
            link
            link_url
          }
        }
        product_video_testimonial {
          title
          subtitle
          testimonials {
            title
            author
            thumb
            video
          }
        }
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        gbp_product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              text
            }
            open
          }
        }
        product_companies_logo_slider {
          title
          subtitle
          list
        }
        size {
          item
        }
        color {
          item
        }
        choose_gender_text
        gender {
          item
        }
        male_size_chart {
          data {
            url
          }
        }
        female_size_chart {
          data {
            url
          }
        }
        male_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        male_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_desktop_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, quality: 100)
            }
          }
        }
        female_mobile_size_chart {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        price_details
        size_popup_text
        language
        my_custom_size_text
        one_size_system_text
        morphology_text
        i_calculate_text
        most_suited_text
        edit_my_information_text
        return_text
        alma_popup_text
      }
    }
    allDirectusLyneupNewPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        lyneup_title
        lyneup_logo
        technology_section {
          title
          image
          list {
            image
            title
          }
        }
        testimonials_section {
          list {
            image
            text
          }
        }
        sedentary_section {
          title
          text
          image
        }
        action_section {
          title
          text
          list {
            text
            image
          }
        }
        virtous_circle_section {
          title
          image
        }
        benefits_section {
          title
          top_image
          bottom_image
          text
        }
        recommend_section {
          title
          list {
            image
            text
          }
        }
        experts_section {
          title
          list {
            image
            name
            designation
            text
            video_text
            video_url
          }
        }
        medical_certificate_section {
          text
          image
        }
        zoom_section {
          title
          list {
            text
            image
          }
        }
        video_section {
          image
          title
        }
        logo_section {
          title
          list {
            image
            text
          }
        }
        lyneup_satisfied_section {
          title
          left_text
          right_text
        }
        lyneup_faq_title
        enterprise_section {
          title
          list {
            image
            text
            link_text
            link_url
          }
        }
        other_products {
          title
          list {
            image
            title
            logo
            subtitle
            text
            button_text
            button_url
          }
        }
        gender_section {
          list {
            image
            text
            value
          }
        }
        color_section {
          list {
            women_image
            men_image
            text
            value
          }
        }
        size_selector_title
        calculate_size_text
        choose_size_text
        add_to_cart_button_text
        male_size_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        female_size_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        size_popup_text {
          men_title
          women_title
          subtitle
          men_image
          women_image
          left_text
          right_text
        }
      }
    }
    allDirectusReviewTranslation(
      filter: { review: { product: { eq: "lyneup" } }, language: { eq: $lang } }
    ) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          stars
          name
        }
      }
    }
    allDirectusAccquiredCompany {
      nodes {
        image
        name
        image_file {
          data {
            url
            thumbnails {
              dimension
              url
            }
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 100, quality: 100)
            }
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        medicale_text
        medicale_logo {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
        months_text {
          month
        }
      }
    }
    shopifyProduct(handle: { eq: "lyneup" }) {
      id: storefrontId
      createdAt
      updatedAt
      descriptionHtml
      description
      handle
      productType
      title
      vendor
      publishedAt
      options {
        id
        name
        values
      }
      variants {
        id: storefrontId
        title
        price
        weight
        sku
        image {
          id
          src: originalSrc
          #   altText
        }
        selectedOptions {
          name
          value
        }
        price
      }
      images {
        src: originalSrc
        id
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "lyneup" } } }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(withStoreContext(LyneUp))
