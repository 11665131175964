import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const ProductMobileInfoBannerWrapper = styled.div`
  width: 100%;
  display: none;
  padding: 10px 0px;

  @media (max-width: 600px) {
    padding: 0px;
    margin-top: 20px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
`

const BannerText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-family: "Gotham";
  font-weight: 500;
  font-style: normal;
  color: #262626;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 0px;
    list-style: none;
  }

  li {
    margin: 0px;
  }
`

export const ProductMobileInfoBanner = ({ intl, data }) => (
  <ProductMobileInfoBannerWrapper>
    <Container>
      {data && <BannerText dangerouslySetInnerHTML={{ __html: data }} />}
    </Container>
  </ProductMobileInfoBannerWrapper>
)

export default injectIntl(ProductMobileInfoBanner)
