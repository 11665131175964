import React from "react"
import styled from "@emotion/styled"
import CompanySlider from "../CompanySlider"
import { SectionHeading } from "../styled"

const CompanySection = ({ data, title, subtitle, borderColor }) => {
  return (
    <AccquiredWrapper>
      <Wrapper>
        {title && (
          <CompanyTitle
            borderColor={borderColor}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}

        {subtitle && (
          <CompanySubTitle dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}

        {data && <CompanySlider data={data} />}
      </Wrapper>
    </AccquiredWrapper>
  )
}

const AccquiredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
  padding: 30px 20px;
  background: ${props => props.theme.colors.white.light};
  align-items: center;
  text-align: center;

  @media (max-width: 850px) {
    padding: 20px 20px;
  }

  @media (max-width: 600px) {
    min-height: auto;
    align-items: flex-start;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  align-items: center;
  text-align: center;

  @media (max-width: 600px) {
    align-items: flex-start;
  }

  .carousel {
    margin-top: 0px;
  }

  a {
    font-weight: bold;
  }

  p {
    margin: 0px;
  }
`

const CompanyTitle = styled.h2`
  text-transform: uppercase;
  color: #262626;
  font-size: 35px;
  line-height: 1;
  font-family: "Gotham Book";
  width: 100%;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 25px;
  padding-bottom: 5px;
  text-align: center;
  position: relative;

  @media (max-width: 850px) {
    font-size: 30px;
  }

  @media (max-width: 600px) {
    text-align: left;
    font-size: 26px;
  }

  &:after {
    content: "";
    width: 100px;
    height: 3px;
    background: #5db9bc;
    background: ${props => props.theme.colors.primary[props.borderColor]};
    left: 0px;
    right: 0px;
    top: 100%;
    position: absolute;
    margin: 0px auto;
    border-radius: 3px;

    @media (max-width: 600px) {
      margin: 0px;
    }
  }
`

const CompanySubTitle = styled.div`
  color: #262626;
  font-size: 16px;
  line-height: 18px;
  font-family: "Gotham Book";
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 600px) {
    text-align: left;
  }
`

export default CompanySection
